import { render, staticRenderFns } from "./DialogForwardMessage.vue?vue&type=template&id=1827699b&scoped=true&"
import script from "./DialogForwardMessage.vue?vue&type=script&lang=ts&"
export * from "./DialogForwardMessage.vue?vue&type=script&lang=ts&"
import style0 from "./DialogForwardMessage.vue?vue&type=style&index=0&id=1827699b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1827699b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VImg,VList,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSheet})
