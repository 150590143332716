var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"right":_vm.right,"kind":_vm.kind,"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"more-option":_vm.moreOption,"with-menu":_vm.withMenu,"with-time-icon":_vm.withTimeIcon,"channel":_vm.channel},on:{"option-item-clicked":_vm.onOptionItemClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var showRight = ref.showRight;
              var timestamp = ref.timestamp;
              var timestampColor = ref.timestampColor;
              var timestampIcon = ref.timestampIcon;
              var color = ref.color;
              var backgroundClass = ref.backgroundClass;
              var textColorClass = ref.textColorClass;
              var textPositionClass = ref.textPositionClass;
              var message = ref.message;
              var textStyle = ref.textStyle;
              var cardStyle = ref.cardStyle;
              var paddingClass = ref.paddingClass;
              var messageText = ref.messageText;
              var edited = ref.edited;
              var showTimestampIcon = ref.showTimestampIcon;
return [_c('v-card',{class:[paddingClass, textColorClass, backgroundClass],style:(cardStyle),attrs:{"flat":"","max-width":"500","color":color}},[(_vm.forwarded)?[_c('div',{staticClass:"pa-0 ma-0 caption"},[_vm._v("Forwarded message")]),_c('div',{staticClass:"pa-0 ma-0 caption"},[_vm._v("From "+_vm._s(_vm.forwardedFrom))])]:_vm._e(),_c('v-row',[_c('div',{staticClass:"attach-block"},[_c('v-img',{staticClass:"ml-1",staticStyle:{"cursor":"pointer"},attrs:{"src":require(("../../assets/" + _vm.attachIcon)),"max-width":"24","max-height":"24"},on:{"click":_vm.onDownloadURL}}),_c('v-card-text',{staticClass:"pa-0 word-wrap",class:[textPositionClass, textColorClass],staticStyle:{"text-decoration":"underline","cursor":"pointer"},style:(textStyle),domProps:{"innerHTML":_vm._s(_vm.convertText)},on:{"click":_vm.onDownloadURL}})],1)]),_c('v-card-actions',{staticClass:"pa-0 ma-0"},[_c('v-spacer'),(edited)?_c('span',{staticClass:"mx-2 timestamp",class:showRight ? 'right': 'left'},[_vm._v("edited")]):_vm._e(),_c('span',{staticClass:"timestamp",class:showRight ? 'right': 'left'},[_vm._v(_vm._s(timestamp))]),(showTimestampIcon && showRight)?_c('v-img',{staticClass:"ml-1",attrs:{"src":require(("../../assets/img/" + timestampIcon)),"max-width":"15","max-height":"8"}}):_vm._e()],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }