



import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action} from 'vuex-class';
import {googleMapStore} from "@/store/modules/googleMaps";

@Component({name: 'google-map-info-window'})
export default class GoogleMapInfoWindow extends Vue {
  @Prop() map: any
  @Prop() position: any

  geocoder: any = new google.maps.Geocoder
  infoWindow: any = new google.maps.InfoWindow

  @Action locationAddress

  @Watch('position')
  onChanged(value, oldValue) {
    this.refreshInfoWindow()
  }


  refreshInfoWindow() {
    if (this.position && this.position.lat !== 0 && this.position.lng !== 0) {
      this.geocodeLocation(this.position)
    }
  }

  geocodeLocation(location) {
    const {locationAddress} = this
    this.geocoder.geocode({location}, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const address = results[0].formatted_address;
          locationAddress(address)
        } else {
          console.warn('No results found')
        }
      } else {
        console.warn('Geocoder failed due to: ' + status)
      }
    });
  }

  mounted() {
    if (this.position) {
      this.refreshInfoWindow()
      this.map.setCenter(this.position);
    }
  }
}
