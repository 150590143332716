










import {Component, Vue, Watch} from 'vue-property-decorator'
import {googleMapStore} from "@/store/modules/googleMaps";
import {GoogleMapStatus} from "@/domain/model/types";
import {Getter} from "vuex-class";

@Component({
  name: 'google-map-autocomplete'
})
export default class GoogleMapAutocomplete extends Vue {
  query: string = ''
  autocomplete: any = null

  createAutocomplete() {
    const input = this.$refs.searchField as HTMLInputElement;
    const options = {
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["establishment"]
    }
    this.autocomplete = new google.maps.places.Autocomplete(input, options)
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace()
      if (place?.geometry?.location) {
        googleMapStore.setAddress({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        })
        this.query = ''
      }
    })
  }

  @Watch('googleMapScriptStatus')
  onGMSStatusChanged(value) {
    if (value === GoogleMapStatus.LOADED) {
      this.createAutocomplete()
    }
  }

  @Getter googleMapScriptStatus

  mounted() {
    if (this.googleMapScriptStatus === GoogleMapStatus.LOADED) {
      this.createAutocomplete()
    } else {
      googleMapStore.loadMap()
    }
  }
}
