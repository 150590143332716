



import Component from 'vue-class-component';
import Vue from 'vue';
import {Prop} from "vue-property-decorator";

@Component({
  name: 'avatar'
})
export default class Avatar extends Vue {
  @Prop() imageUrl?: string;
  @Prop({default: false}) online?: boolean;

  get classAvatar(): string {
    let classList: string[] = ['avatar']
    if (this.online) {
      classList.push('online')
    }
    return classList.join(' ')
  }

  get styleAvatar(): object {
    return { backgroundImage: `url('${this.imageUrl}')` }
  }
}
