var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"with-menu":_vm.withMenu},on:{"option-item-clicked":_vm.onOptionItemClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var message = ref.message;
var showRight = ref.showRight;
var messageText = ref.messageText;
var timestamp = ref.timestamp;
var timestampColor = ref.timestampColor;
var timestampIcon = ref.timestampIcon;
var paddingClass = ref.paddingClass;
var cardStyle = ref.cardStyle;
return [_c('v-card',{staticClass:"chat-system-message",attrs:{"max-width":"500"}},[_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[(_vm.isRecipient)?_c('span',{staticClass:"link-text",domProps:{"textContent":_vm._s(_vm.senderName)},on:{"click":_vm.onViewSenderContact}}):_c('span',{staticStyle:{"font-weight":"normal"},domProps:{"textContent":_vm._s(_vm.senderName)}}),_vm._v(" forwarded case to "),(_vm.isSender)?_c('span',{staticClass:"link-text",domProps:{"textContent":_vm._s(_vm.recipientName)},on:{"click":_vm.onViewRecipientContact}}):_c('span',{staticStyle:{"font-weight":"normal"},domProps:{"textContent":_vm._s(_vm.recipientName)}})])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }