









































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {dateToYMD, dateToYMD_HM, formatMonth, formatNoteDate} from '@/utils/helpers';
import {profileStore} from "@/store/modules/profile";

@Component({
  name: 'dialog-detail-schedule',
  components: {
    BaseDialog,
    AvatarWithStatus
  },
  filters: {
    formatNoteDate
  }
})
export default class DialogDetailSchedule extends Vue {
  @Prop({type: Boolean, default: false}) value;
  @Prop({type: Object}) appointment;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get event() {
    return {
        id: this.appointment.id,
        name: this.appointment.customer.fullName,
        customerId: this.appointment.customer.id,
        photoUrl: (this.appointment.customer.photoUrl && this.appointment.customer.photoUrl.thumbnail) || null,
        date: dateToYMD(this.appointment.startDate.toDate()),
        start: dateToYMD_HM(this.appointment.startDate.toDate()),
        end: dateToYMD_HM(this.appointment.endDate.toDate()),
        details: this.appointment.comment,
        color: this.appointment.selected ? 'primary' : 'white',
        value: false,
        timer: this.appointment.timer,
        events: this.appointment.events,
        canceled: this.appointment.canceled,
        conducted: this.appointment.conducted,
        associateName: this.appointment.associate.name,
        associatePhotoUrl: this.appointment.associate.photoUrl?.thumbnail
      }
  }
}
