




































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '../BaseDialog.vue';
import SearchView from '@/components/SearchView.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {firstWord, twoChars} from '@/utils/helpers';
import {Getter} from 'vuex-class';
import {profileStore} from '@/store/modules/profile';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {directoryStore} from '@/store/modules/directory/directoryStore';

@Component({
  name: 'dialog-forward-message',
  components: {GroupAvatar, AvatarWithStatus, SearchView, BaseDialog}
})
export default class DialogForwardMessage extends Vue {
  @Prop() value!: boolean;

  lastSelectedItem: any = null
  customItems: any[] = []
  customItemsOriginal: any[] = []
  userId?: string | null = profileStore.t2bUser?.id

  @Getter activeUrgentChats;
  @Getter activeNormalChats;
  @Getter activeLowChats;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get contacts() {
    return directoryStore.allContacts
        .filter((item) => !item.associateIDs?.includes(this.userId!))
        .map((item) => {
          return {data: item, selected: false}
        })
  }

  get items() {
    return [...this.urgentChats, ...this.normalChats, ...this.lowChats].map((item) => {
      return {data: item, selected: false, chat: true}
    })
  }

  get urgentChats() {
    return this.activeUrgentChats.filter(this.predicatePersonal)
  }

  get normalChats() {
    return this.activeNormalChats.filter(this.predicatePersonal)
  }

  get lowChats() {
    return this.activeLowChats.filter(this.predicatePersonal)
  }

  predicatePersonal = (chat) => chat.memberIDs.includes(this.userId);

  photoUrl(item) {
    return item.photoUrl && item.photoUrl.thumbnail;
  }

  getLetters(text) {
    return twoChars(text);
  }

  isOnline(contact) {
    const item = contact.associate || contact;
    const status = item.status;
    return status && status.online;
  }

  customerPhotoUrl(chat: any) {
    const customer = chat.customer
    return (customer && customer.photoUrl) ? customer.photoUrl.thumbnail : null;
  }

  imagesUrls(chat: any) {
    return Object.values(chat.members)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.thumbnail);
  }

  onlineStatus(chat: any): boolean {
    return chat.customer && chat.customer.status && chat.customer.status.online;
  }

  chatItemTitle(chat: any) {
    if (!chat) {
      return '';
    }
    switch (chat.subtype) {
      case 1:
      case 2: {
        return chat.customer && chat.customer.fullName;
      }
      case 3:
        return chat.title || Object.values(chat.members).map((item: any) => firstWord(item.name)).toString();
      default:
    }
  }

  onItemSelected(item) {
    if (this.lastSelectedItem && this.lastSelectedItem.data.id !== item.data.id) {
      // uncheck selected
      const updatedContact = {...this.lastSelectedItem, selected: false};
      const predicate = (item) => item.data.id === this.lastSelectedItem!.data.id;
      const indexOf1 = this.customItems.findIndex(predicate);
      const indexOf2 = this.customItemsOriginal.findIndex(predicate);
      this.customItems.splice(indexOf1, 1, updatedContact);
      this.customItemsOriginal.splice(indexOf2, 1, updatedContact);
    }

    const indexOf1 = this.customItems.indexOf(item);
    const indexOf2 = this.customItemsOriginal.indexOf(item);
    const newItem = {...item, selected: !item.selected};
    this.customItems.splice(indexOf1, 1, newItem);
    this.customItemsOriginal.splice(indexOf2, 1, newItem);

    this.lastSelectedItem = item
  }

  search(term) {
    if (term === null || term === '') {
      this.customItems = this.customItemsOriginal;
      return;
    }
    const searchString = term.toLowerCase();
    this.customItems = this.customItemsOriginal.filter((item) => {
      return item.chat ? item.data.customer.fullName.toLowerCase().startsWith(searchString) : item.data.name.toLowerCase().startsWith(searchString);
    });
  }

  onForward() {
    const {chat, data} = this.lastSelectedItem
    if (chat) {
      this.$emit('forward-message-to-chat', data.id)
    } else {
      this.$emit('forward-message-to-contact', data.id)
    }
    this.show = false
  }

  created() {
    this.customItems = [...this.items, ...this.contacts]
    this.customItemsOriginal = Object.assign([], this.customItems)
  }
}
