






















import GoogleMapLoader from './GoogleMapLoader.vue';
import GoogleMapMarker from './GoogleMapMarker.vue';
import GoogleMapLine from './GoogleMapLine.vue';
import GoogleMapInfoWindow from './GoogleMapInfoWindow.vue';
import {mapBaseSettings} from '@/common/mapSettings';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

@Component({
  name: 'google-map',
  components: {GoogleMapInfoWindow, GoogleMapLine, GoogleMapMarker, GoogleMapLoader}
})
export default class GoogleMap extends Vue {
  @Prop() location: any
  @Prop({default: false}) fullscreen?: boolean
  @Prop({default: false}) showCurrentLocation?: boolean
  @Prop({default: '800'}) width?: any
  @Prop({default: '600'}) height?: any
  @Prop({type: Number, default: 15}) zoom?: number

  @Getter selectedLocation

  get currentLocation() {
    return this.selectedLocation || this.location
  }

  get marker() {
    return {
      id: '_id',
      position: this.currentLocation
    }
  }

  get mapCenter() {
    return this.marker.position
  }

  get mapConfig() {
    return {
      ...mapBaseSettings,
      center: this.mapCenter
    }
  }
}
