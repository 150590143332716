










































































import BaseDialog from '@/components/BaseDialog.vue'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import AvatarWithStatus from '@/components/AvatarWithStatus.vue'
import {channelsStore} from '@/store/modules/channels';
import {Channel} from '@/domain/model/types';

@Component({
  name: 'dialog-create-channel',
  components: {BaseDialog, AvatarWithStatus},
})
export default class DialogCreateChannel extends Vue {
  @Prop() value!: boolean
  @Prop({
    default: () => {
    }, required: false
  }) channel?: Channel

  imageFile: File | null = null
  validate: boolean = false
  form: any = {
    id: null,
    imageUrl: null,
    name: '',
    description: ''
  }

  @Watch('form', {deep: true})
  onFormChanged() {
    const form: any = this.$refs.channelInfo
    if (!form) {
      this.validate = false
    } else {
      this.validate = form.validate()
    }
  }

  get isLoading() {
    return channelsStore.loading
  }

  get show() {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  get isCreate() {
    return !this.channel
  }

  public onChannelImageFileSelected(event) {
    this.imageFile = event.target.files[0];
    if (this.imageFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.form.imageUrl = e.target.result || null;
      };
      reader.readAsDataURL(this.imageFile);
    }
  }

  public async onCreateChannel() {
    const channelData: any = {
      name: this.form.name,
      description: this.form.description,
      imageFile: this.imageFile
    }
    if (this.isCreate) {
      const id = await channelsStore.addChannel(channelData)
      this.show = false
      this.$emit('on-create', id)
    } else {
      const id = await channelsStore.editChannel(channelData)
      this.show = false
      this.$emit('on-edit', id)
    }
  }

  init() {
    if (!this.isCreate) {
      this.form.id = this.channel?.id
      this.form.imageUrl = this.channel?.imageUrl
      this.form.name = this.channel?.name
      this.form.description = this.channel?.description
    }
  }

  created() {
    this.init()
  }

  updated() {
    this.init()
  }
}
