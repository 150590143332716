




import {LINE_PATH_CONFIG} from '@/common/mapSettings';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'google-map-line',})
export default class GoogleMapLine extends Vue {
    @Prop() map: any
    @Prop() path: any

    mounted() {
        new google.maps.Polyline({
            path: this.path,
            map: this.map,
            ...LINE_PATH_CONFIG
        })
    }
}
