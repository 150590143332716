import store from '@/store'
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {onSnapshot, Unsubscribe} from 'firebase/firestore';
import axios from '@/plugins/axios';
import OT, {Publisher, Session} from '@opentok/client';
import {profileStore} from '@/store/modules/profile';
import {chat} from '@/data/firebase';

@Module({
  name: 'video-call-store',
  store,
  dynamic: true
})
export default class VideoCallStore extends VuexModule {
  private _unsubscribeVideoCall: Unsubscribe | null = null
  private _currentVideoCallSession: any = null
  private _videoSession: Session | null = null
  private _publisher?: Publisher
  private _videoMuted: boolean = true
  private _voiceMuted: boolean = true
  private _published: boolean = false
  private _outgoingCall: boolean = false
  private _incomingCall: boolean = false
  private _eventsMap: any

  get eventsMap() {
    return this._eventsMap
  }

  get published() {
    return this._published
  }

  get videoMuted() {
    return this._videoMuted
  }

  get voiceMuted() {
    return this._voiceMuted
  }

  get unsubscribeVideoCall() {
    return this._unsubscribeVideoCall
  }

  get currentVideoCallSession() {
    return this._currentVideoCallSession
  }

  get activeVideoCall() {
    return this._currentVideoCallSession?.videoCall
  }

  get userId() {
    return profileStore.t2bUser?.id
  }

  get outgoingVideoCall() {
    return this._outgoingCall
  }

  get incomingVideoCall() {
    return this._incomingCall
  }

  get connected() {
    const published = this._currentVideoCallSession?.videoCall?.published || {};
    return Object.values(published).filter((value) => value === true).length > 0
  }

  get videoSession() {
    return this._videoSession
  }

  get publisher() {
    return this._publisher
  }

  @Mutation
  public setEventsMap(eventsMap: any) {
    this._eventsMap = eventsMap
  }

  @Mutation
  public setPublished(value: boolean) {
    this._published = value
  }

  @Mutation
  public setVideoMuted(value: boolean) {
    this._videoMuted = value
  }

  @Mutation
  public setVoiceMuted(value: boolean) {
    this._voiceMuted = value
  }

  @Action
  public async startVideoCall(chatId: string) {
    console.log('startVideoCall, chatId=' + chatId)
    this.setOutgoingVideoCall(true)
    const unsubscribe = onSnapshot(chat(chatId), (snapshot) => {
      if (snapshot.exists()) {
        this.setCurrentVideoCallSession(snapshot.data())
      }
    })
    this.setUnsubscribeVideoCall(unsubscribe)

    try {
      const {apiKey, sessionId, token} = await this.generateVideoSessionConnectionData(chatId);
      this.setVideoSession(OT.initSession(apiKey, sessionId));

      const eventsMap = {
        sessionConnected: (evt) => {
          console.log('sessionConnected:' + evt.id)
        },
        connectionCreated: (evt) => {
          console.log('connectionCreated:' + evt.id)
        },
        streamCreated: (evt) => {
          console.log('streamCreated:' + evt.id)
          this.videoSession?.subscribe(evt.stream, 'subscriber', {
            fitMode: 'cover',
            insertDefaultUI: true,
            insertMode: 'append',
            showControls: false,
            width: '100%',
            height: '100%'
          }, (err) => {
            if (err) {
              console.error(err)
              return
            }
            console.log('session.subscribe - success')
          })
          if (!this.published) {
            this.setPublished(true)
            const publisher = OT.initPublisher('publisher', {
              fitMode: 'cover',
              insertDefaultUI: true,
              insertMode: 'replace',
              showControls: false,
              width: 200,
              height: 140,
              publishAudio: !this.voiceMuted,
              publishVideo: !this.videoMuted
            }, (err) => {
              if (err) {
                this.setPublished(false)
                console.error(err)
              }
              console.log('OT.initPublisher - success')
            })
            this.setPublisher(publisher)
            this.videoSession?.publish(publisher, (err) => {
              if (err) {
                console.error(err)
                this.setPublished(false)
                return
              }
              console.log('session.publish - success')
            })
          }
        }
      };
      this.setEventsMap(eventsMap);
      this.videoSession?.on(eventsMap, this)

      this.videoSession?.connect(token, (err) => {
        if (err) {
          console.error(err)
          return
        }
        console.log('session.connect - success')
      })
    } catch (err) {
      console.error(err)
      this.setOutgoingVideoCall(false)
    }
  }

  @Action
  public async joinVideoCall({chatId, videoCallId}) {
    console.log('joinVideoCall, chatId=' + chatId + ', videoCallId=' + videoCallId)
    if (!!this.currentVideoCallSession) {
      console.log('joinVideoCall abort - video call is present')
      return
    }
    this.setIncomingVideoCall(true)
    const unsubscribe = onSnapshot(chat(chatId), (snapshot) => {
      if (snapshot.exists()) {
        this.setCurrentVideoCallSession(snapshot.data())
      }
    })
    this.setUnsubscribeVideoCall(unsubscribe)
    try {
      const {apiKey, sessionId, token} = await this.generateVideoSessionConnectionDataJoin({chatId, videoCallId});
      this.setVideoSession(OT.initSession(apiKey, sessionId));

      const eventsMap = {
        sessionConnected: (evt) => {
          console.log('sessionConnected:' + JSON.stringify(evt))
        },
        connectionCreated: (evt) => {
          console.log('connectionCreated:' + JSON.stringify(evt))
        },
        streamCreated: (evt) => {
          console.log('streamCreated:' + JSON.stringify(evt))
          this.videoSession?.subscribe(evt.stream, 'subscriber', {
            fitMode: 'cover',
            insertDefaultUI: true,
            insertMode: 'append',
            showControls: false,
            width: '100%',
            height: '100%'
          }, (err) => {
            if (err) {
              console.error(err)
              return
            }
            console.log('session.subscribe - success')
          })
        }
      };
      this.setEventsMap(eventsMap);
      this.videoSession?.on(eventsMap, this)

      this.videoSession?.connect(token, (err) => {
        if (err) {
          console.error(err)
          return
        }
        console.log('session.connect - success')
      })
    } catch (err) {
      console.error(err)
      this.setIncomingVideoCall(false)
    }
  }

  @Action
  public endVideoCall() {
    console.log('endVideoCall')
    this.setIncomingVideoCall(false)
    this.setOutgoingVideoCall(false)
    this.videoSession?.off(this.eventsMap, this)
    this.videoSession?.disconnect()
    this.setEventsMap(null)
    console.log('sessionDisconnect')
    if (!!this.unsubscribeVideoCall) {
      this.unsubscribeVideoCall()
      console.log('unsubscribeVideoCall')
    }
    this.setUnsubscribeVideoCall(null)
    this.setCurrentVideoCallSession(null)
    this.setVideoSession(null)
    console.log('reset video call')
  }

  @Action
  public acceptVideoCall(voiceOnly: boolean) {
    if (!this.published) {
      this.setPublished(true)
      const publisher = OT.initPublisher('publisher', {
        fitMode: 'cover',
        insertDefaultUI: true,
        insertMode: 'replace',
        showControls: false,
        width: 200,
        height: 140,
        publishAudio: true,
        publishVideo: !voiceOnly
      }, (err) => {
        if (err) {
          console.error(err)
        }
        console.log('OT.initPublisher - success')
      })
      this.setPublisher(publisher)
      this.videoSession?.publish(publisher, (err) => {
        if (err) {
          console.error(err)
          this.setPublished(false)
          console.log('session.publish - failure')
        }
        console.log('session.publish - success')
      })
      this.setVideoMuted(voiceOnly)
    }
  }

  @Action
  public async generateVideoSessionConnectionData(chatId: string) {
    console.log('generateVideoSessionConnectionData')
    try {
      const currentUser = this.context.rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const result = await axios.post(`/textsessions/${chatId}/videocalls`, null, {
        headers: {Authorization: `Bearer ${token}`}
      });
      console.log(result)
      if (result.status === 200) {
        return result.data;
      }
    } catch (err) {
      console.error(err)
    }
    return null;
  }

  @Action
  public async generateVideoSessionConnectionDataJoin({chatId, videoCallId}) {
    console.log('generateVideoSessionConnectionDataJoin')
    try {
      const currentUser = this.context.rootGetters.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const result = await axios.patch(`/textsessions/${chatId}/videocalls/${videoCallId}`, {sessionId: ''}, {
        headers: {Authorization: `Bearer ${token}`}
      });
      console.log(result)
      if (result.status === 200) {
        return result.data;
      }
    } catch (err) {
      console.error(err)
    }
    return null;
  }

  @Action
  public toggleAudio() {
    this.setVoiceMuted(!this.voiceMuted)
    this.publisher?.publishAudio(!this.voiceMuted)
  }

  @Action
  public toggleVideo() {
    this.setVideoMuted(!this.videoMuted)
    this.publisher?.publishVideo(!this.videoMuted)
  }

  @Mutation
  public setOutgoingVideoCall(value: boolean) {
    this._outgoingCall = value
  }

  @Mutation
  public setIncomingVideoCall(value: boolean) {
    this._incomingCall = value
  }

  @Mutation
  private setVideoSession(videoSession: Session | null) {
    this._videoSession = videoSession
  }

  @Mutation
  private setPublisher(publisher: Publisher) {
    this._publisher = publisher
  }

  @Mutation
  private setUnsubscribeVideoCall(unsubscribe: Unsubscribe | null) {
    this._unsubscribeVideoCall = unsubscribe
  }

  @Mutation
  private setCurrentVideoCallSession(textSession: any) {
    this._currentVideoCallSession = textSession
  }
}

export const videoCallStore = getModule(VideoCallStore)
