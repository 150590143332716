









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {googleMapStore} from "@/store/modules/googleMaps";
import {GoogleMapStatus} from "@/domain/model/types";
import {mixins} from "vue-class-component";
import Notifications from "@/components/mixins/Notifications";

@Component({name: 'google-map-loader'})
export default class GoogleMapLoader extends mixins(Notifications) {
  @Prop() mapConfig?: any
  @Prop() card?: boolean
  @Prop() location?: any
  @Prop({default: '800'}) width?: any
  @Prop({default: '600'}) height?: any
  @Prop({default: false}) fullscreen?: boolean
  @Prop({default: false}) showCurrentLocation?: boolean
  @Prop({type: Number}) zoom

  map: any = null
  mapEventListener: any = null

  get mapStyle() {
    if (this.fullscreen) {
      return {
        width: '100vw',
        height: '100vh'
      }
    }
    return {
      width: `${this.width}px`,
      height: `${this.height}px`
    }
  }

  get currentAddress() {
    return googleMapStore.currentAddress
  }

  @Watch('showCurrentLocation')
  onShowCurrentLocation(after: boolean, before: boolean) {
    if (after) {
      this.initLocation()
    }
  }

  @Watch('zoom')
  onZoomChanged(after, before) {
    this.map.setZoom(after)
  }

  @Watch('currentAddress')
  onAddressChanged(value) {
    if (value) {
      this.onLocationChanged(value)
    }
  }

  @Watch('googleMapScriptStatus')
  onGMSStatusChanged(value) {
    if (value === GoogleMapStatus.LOADED) {
      this.initMap()
    }
  }

  @Getter googleMapScriptStatus
  @Action locationSelected

  onLocationChanged(position) {
    this.locationSelected(position)
    this.map.panTo(new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng)))
  }

  initMap() {
    this.map = new google.maps.Map(this.$refs.googleMap as any, this.mapConfig);
    const service = new google.maps.places.PlacesService(this.map)
    googleMapStore.setPlacesService(new google.maps.places.PlacesService(this.map))
    if (!this.card) {
      // Listen for clicks and add the location of the click to firebase.
      this.mapEventListener = this.map.addListener('click', (event) => {
        const position = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        this.onLocationChanged(position)
      });
      this.initLocation()
    }
  }

  initLocation() {
    if (this.location) {
      this.onLocationChanged(this.location)
      return
    }
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.onLocationChanged(pos)
      }, () => {
        this.handleLocationError(true, this.map.getCenter());
      });
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false, this.map.getCenter());
    }
  }

  handleLocationError(browserHasGeolocation, pos) {
    if (browserHasGeolocation) {
      this.showInfo(`The Geolocation service failed.`)
    } else {
      this.showInfo(`Your browser doesn\'t support geolocation..`)
    }
  }

  mounted() {
    if (this.googleMapScriptStatus === GoogleMapStatus.LOADED) {
      this.initMap()
    } else {
      googleMapStore.loadMap()
    }
  }

  destroyed() {
    if (this.mapEventListener) {
      this.mapEventListener.remove()
    }
  }
}
