var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"message":_vm.message,"user-id":_vm.userId,"with-menu":_vm.withMenu,"centered":_vm.centered},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var message = ref.message;
var messageText = ref.messageText;
var timestamp = ref.timestamp;
var timestampColor = ref.timestampColor;
var timestampIcon = ref.timestampIcon;
var paddingClass = ref.paddingClass;
var messageClass = ref.messageClass;
var cardStyle = ref.cardStyle;
return [_c('v-card',{class:[messageClass],attrs:{"max-width":"500"}},[(_vm.appointId)?_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.customer))]),_vm._v(" scheduled an appointment with "),(_vm.own)?_c('span',[_vm._v("you")]):_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.sender))]),_vm._v(". "),_c('br'),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-outline-primary mt-4 px-10",on:{"click":_vm.onDetail}},[_vm._v("View detail")])])]):_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[(_vm.own)?_c('span',[_vm._v("You")]):_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.sender))]),_vm._v(" sent request to schedule an appointment to "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.customer))]),(message.data.declined)?_c('v-card-actions',[_c('v-spacer'),_c('a',{staticClass:"btn btn-light mx-auto mt-3"},[_vm._v("Declined")]),_c('v-spacer')],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }