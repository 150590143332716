


















































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '../messages/BaseMessage.vue';
import {lineBreaks} from '@/utils/helpers';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';

@Component({
  name: 'attachment-message',
  components: {BaseMessage}
})
export default class AttachmentMessage extends mixins(Notifications){
  @Prop({default: true}) withTimeIcon?: boolean;
  @Prop({default: false}) right?: boolean;
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop() moreOption?: boolean;
  @Prop({default: true}) withMenu!: boolean;
  @Prop({default: false}) forwarded?: boolean;
  @Prop({default: false}) channel?: boolean;

  get forwardedFrom() {
    return this.message.data?.forwardedMessage?.sender?.name || 'unknown'
  }

  get convertText() {
    return lineBreaks(this.message.data?.text)
  }

  get attachIcon() {
    return this.message.data?.sender?.uid === this.userId ? '_attach_right.svg' : '_attach_left.svg';
  }

  get attachUrl() {
    return this.forwarded ? this.message.data.forwardedMessage?.attachment?.url : this.message.data.attachment?.url
  }

  onDownloadURL() {
      if(this.attachUrl.includes('blob')){
          return this.showIssue("Upload in progress…")
      }
      window.open(this.attachUrl, "_blank");
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }
}
