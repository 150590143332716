






























































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {Action, Getter} from 'vuex-class';
import {BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';
import SearchView from '@/components/SearchView.vue';
import PersonalContactListItem from '@/components/PersonalContactListItem.vue';

@Component({
  name: 'dialog-send-contact',
  components: {PersonalContactListItem, SearchView, BaseDialog, ContactsList}
})
export default class DialogSendContact extends Vue {
  @Prop() value!: boolean;
  @Prop({default: false}) directoryOnly!: boolean;
  @Prop({default: false}) channel?: boolean;

  selectedContact: BusinessContact | null = null;
  loading: boolean = false
  
  get businessId() {
    return applicationStore.businessId;
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  @Getter customers;

  get personalContacts() {
    return this.customers.filter((customer) => customer.personal && customer.personal.includes(this.t2bUser!.id));
  }

  get sharedContacts() {
    return this.customers.filter((customer) => customer.shared);
  }

  get filterContacts() {
    if (this.channel) {
      return []
    }
    return [this.t2bUser.id]
  }

  @Action sendContact

  private onContactSelected(contact: BusinessContact[]) {
    this.selectedContact = contact[0];
  }

  private onSendContact() {
    if (!this.selectedContact) {
      return;
    }
    this.loading = true
    this.$emit('on-selected', this.selectedContact)
    this.loading = false
    this.show = false;
  }
}
