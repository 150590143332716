
























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '@/components/messages/BaseMessage.vue';
import {messageType} from '@/domain/model/types';
import {directoryStore} from '@/store/modules/directory/directoryStore';

@Component({
  name: 'forward-message',
  components: {BaseMessage}
})
export default class ForwardMessage extends Vue {
  @Prop() showAvatar?: boolean
  @Prop() message: any
  @Prop() userId?: string
  @Prop({default: true}) withMenu!: boolean

  get messageTypes() {
    return messageType;
  }

  get recipient() {
    return this.message.data?.recipient
  }

  get sender() {
    return this.message.data?.sender
  }

  get isSender() {
    return this.userId === this.sender?.uid
  }

  get isRecipient() {
    return this.userId === this.recipient?.uid
  }

  get senderName() {
    return this.isRecipient ? this.sender?.name : 'You'
  }

  get recipientName() {
    return this.isSender ? this.recipient?.name : 'You'
  }

  async onViewRecipientContact() {
    const contact = await directoryStore.getContactById(this.recipient?.contactId)
    this.$emit('on-view-contact', contact)
  }

  async onViewSenderContact() {
    const contact = await directoryStore.getContactById(this.sender?.contactId)
    this.$emit('on-view-contact', contact)
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }
}
