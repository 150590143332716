import {Component, Vue} from 'vue-property-decorator';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';

@Component({name: 'BaseApplication'})
export default class BaseApplication extends Vue {
    protected get currentUser() {
        return profileStore.currentUser;
    }

    protected get isAdmin() {
        return applicationStore.isAdmin;
    }

    protected get business() {
        return applicationStore.business;
    }
}
