

























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {twoChars} from '@/utils/helpers';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ToolTip from '@/components/custom/ToolTip.vue';

@Component({
  name: 'personal-contact-list-item',
  components: {AvatarWithStatus, ToolTip}
})
export default class PersonalContactListItem extends Vue {
  @Prop() item?: any;
  @Prop({default: false}) inPersonal?: boolean;
  @Prop({default: true}) border?: boolean;
  @Prop({default: false}) rounded?: boolean;
  @Prop({default: true}) listItem?: boolean;
  @Prop({default: '44'}) avatarSize?: string;

  @Getter selectedCustomer;

  isOnload: boolean = true;
  subTitleCropped: boolean = false;

  get selected(): boolean {
    return !this.isOnload && this.selectedCustomer && this.selectedCustomer.id === this.item.id;
  }

  get title(): string {
    return this.item.fullName;
  }

  get subtitle(): string {
    return `${this.item.email}`;
  }
 

  get photoUrl(): string {
    return this.item.photoUrl ? this.item.photoUrl.normal : null;
  }

  get online(): boolean {
    return this.item.status && this.item.status.online;
  }

  get abbreviation() {
    return twoChars(this.title);
  }  

  onItemSelected() {
    this.isOnload = false;
    //if (!this.selected) {
      this.$emit('contact-clicked', this.item);
   // }
  }

  subTitleWrap() {    
    try{
      this.$nextTick(() => {        
          let wrap = this.$refs.wrap        
          this.subTitleCropped = wrap['offsetWidth'] < wrap['scrollWidth'];        
      });    
    } catch (e) {    
      console.log(e);
    }
    return true   
  }

  created() {
    console.log('PersonalContactListItem:: created');
  }
};
