



































import {Component, Prop, Vue} from 'vue-property-decorator';
import GoogleMap from '@/components/maps/GoogleMap.vue';
import GoogleMapAutocomplete from "@/components/maps/GoogleMapAutocomplete.vue";
import {Getter} from 'vuex-class';

@Component({
  name: 'dialog-send-image',
  components: {
    GoogleMap, GoogleMapAutocomplete
  }})
export default class DialogSendImage extends Vue {
  @Prop() value?: boolean;
  @Prop() message?: any;
  @Prop() forwarded?: boolean;

  currentLocation: boolean = false
  zoom: number = 15

  get show() {
    return !!this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  get title() {
    return this.forwarded
        ? this.message.data.forwardedMessage.text
        : this.message.data.text;
  }

  get imageObj() {
    return this.forwarded
        ? this.message.data.forwardedMessage.image
        : this.message.data.image;
  }

  get imageUrl() {
    return this.imageObj.url;
  }

  onClose() {
    this.$emit('on-close')
    this.show = false
  }

  mounted() {
    document.addEventListener("keydown", (e) => {
        if (e.key === 'Escape') {
          this.$emit('on-close')
            // Or any other way you want to close your modal
        }
    })
  }
}
