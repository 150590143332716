






































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {Getter} from 'vuex-class';

@Component({
  name: 'dialog-delete-messages',
  components: {BaseDialog}
})
export default class DialogDeleteMessages extends Vue {
  @Prop() value!: boolean;
  @Prop({default: false}) channelMessage!: boolean;
  @Prop({default: false}) foreignMessage!: boolean;
  @Prop({default: 0}) messagesCount!: number

  @Getter selectedTextSession;

  loading: boolean = false
  forAll: boolean = false

  get title() {
    return `Do you want to delete ${this.messagesCount} message${this.messagesCount > 1 ? 's' : ''}?`
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  onCancel() {
    this.show = false
    this.$emit('on-cancel')
  }

  onDelete() {
    this.show = false
    this.$emit('on-delete', this.forAll)
  }
}
