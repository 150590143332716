









































































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator'
import Notifications from '@/components/mixins/Notifications'
import {Unsubscribe} from 'firebase/firestore'
import DialogConfirm from '@/components/DialogConfirm.vue'
import DialogSendContact from '@/components/chat/DialogSendContact.vue'
import DialogSendLocation from '@/components/chat/DialogSendLocation.vue'
import DialogSendImage from '@/components/chat/DialogSendImage.vue';
import ToolTip from '@/components/custom/ToolTip.vue'
import ChannelInfo from '@/components/ChannelInfo.vue'
import EmptyState from './EmptyState.vue'
import {mixins} from 'vue-class-component'
import {channelsStore} from '@/store/modules/channels'
import {profileStore} from '@/store/modules/profile'
import StandardMessage from '@/components/messages/StandardMessage.vue';
import ImageMessage from '@/components/messages/ImageMessage.vue';
import AttachmentMessage from './messages/AttachmentMessage.vue';
import LocationMessage from '@/components/messages/LocationMessage.vue';
import SystemMessage from '@/components/messages/SystemMessage.vue';
import BusinessCardMessage from '@/components/messages/BusinessCardMessage.vue';
import {copyTextToClipboard, downloadFile, formatSectionDate, getCustomImageUrl} from '@/utils/helpers';
import NoMessages from '@/components/NoMessages.vue';
import {Action, Getter} from 'vuex-class';
import ContactInfo2 from '@/components/ContactInfo2.vue';
import CustomerProfile from '@/components/business/customers/CustomerProfile.vue';
import {
  ACTION_MESSAGE_DOWNLOAD,
  ACTION_MESSAGE_COPY,
  ACTION_MESSAGE_DELETE,
  ACTION_MESSAGE_EDIT,
  ACTION_MESSAGE_MORE
} from '@/common/context-menu-actions';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import DialogDeleteMessages from '@/components/DialogDeleteMessages.vue';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import {applicationStore} from '@/store/modules/application';
import constants from '@/common/constants';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);
@Component({
  name: 'channel-detail',
  components: {
    DialogDeleteMessages,
    CustomerProfile,
    ContactInfo2,
    BusinessCardMessage,
    SystemMessage,
    LocationMessage,
    ImageMessage,
    AttachmentMessage,
    StandardMessage,
    ToolTip,
    ChannelInfo,
    EmptyState,
    DialogConfirm,
    DialogSendContact,
    DialogSendLocation,
    DialogSendImage,
    NoMessages,
    AvatarWithStatus
  },
  filters: {formatSectionDate}
})
export default class ChannelDetail extends mixins(Notifications) {
  @Prop() channelId?: string;

  private viewContactId: string | null = null
  private channelMessageText: string = ''
  private drawerChannelInfo: boolean = false;
  private unsubscribeChannel: Unsubscribe | null = null
  private unsubscribeMessages: Unsubscribe | null = null
  private showDialogSendContact: boolean = false
  private dialogConfirmAction: boolean = false
  private dialogConfirmActionTitle: string = ''
  private dialogConfirmActionMessage: string = ''
  private dialogConfirmActionCancelShow: boolean = true
  private onDialogConfirmActionPositiveCallback: any
  private onDialogConfirmCancel: any
  private confirm: string = ''
  private error: any = {message: null}
  private file: any = null
  private moreOption: boolean = false
  private messageToEdit: any = null
  private showDeleteMessagesDialog: boolean = false
  private showMap: boolean = false
  private location: any = null

  private imageTypeMessage: any = null;
  private imageTypeMessageForward: boolean = false;
  private  showImage: boolean = false;

  _files: any = null
  textColor: string = ''
  btnColor: string = 'error'

  private loading: boolean = false;
  private isSMS: boolean = false;
  private chatTypeVisible: boolean = false;

  private phoneNumbers:any = [];

  chatTypes: any = [
  {
    text: 'In App',
    value: false
  },
  {
    text: 'SMS',
    value: true
  }];

  @Getter selectedLocation
  @Getter selectedTextSession;
  @Action sendSMS;
  @Action sendImageMessage;
  
  get channel() {
    return channelsStore.selectedChannel
  }

  get messageItems() {
    return channelsStore.channelMessages
  }

  get messagesLoaded() {
    return channelsStore.messagesLoaded
  }

  get name() {
    return this.channel?.name
  }

  get imageUrl() {
    return this.channel?.imageUrl
  }

  get membersCount() {
    return this.channel?.memberIDs?.length || 0
  }

  get disabled() {
    return !!this.channel?.disabled
  }

  get isEmpty() {
    return !this.messageItems.length
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get userId() {
    return this.t2bUser?.id;
  }

  get selectedMessages() {
    return this.messageItems.filter(message => message.selected);
  }

  get selectedMessagesCount() {
    return this.selectedMessages.length;
  }

  get messageSelected() {
    return this.messageToEdit
  }

  get isFirstSMS() {    
    return false; //this.messages.filter(message => message?.data?.isSMS == true).length > 0 ? false : true;        
  }

  get smsSenderId() {
    return applicationStore.smsSenderId
  }
  
  private async getPhoneNumbers(){
    
    if(this.membersCount > 0){
      const ids: any = this.channel?.memberIDs;
      this.phoneNumbers = await customersStore.getSubscribersPhone(ids); 
    }else{
      this.phoneNumbers = [];
    }    
  }
 

  @Action resetSelectedLocation;

  unsubscribeAll() {
    if (this.unsubscribeChannel) {
      try {
        this.unsubscribeChannel();
      } catch (e) {
      }
      this.unsubscribeChannel = null;
    }
    if (this.unsubscribeMessages) {
      try {
        this.unsubscribeMessages();
      } catch (e) {
      }
      this.unsubscribeMessages = null;
    }
  }

  async init(channelId?: string) {
    if (!channelId) {
      return
    }
    this.unsubscribeChannel = await channelsStore.loadChannel(channelId)
    this.unsubscribeMessages = await channelsStore.loadChannelMessages(channelId);
    await channelsStore.loadChannelMembers(channelId)
  }

  private async onSendContact(contact: any) {
    try {
      await channelsStore.sendChannelContactMessage(contact)
      this.showInfo('Contact has been sent')
    } catch (error: any) {
      this.showIssue(error.message)
    }
  }

  private viewContact(contact: any) {
    if (contact.type <= 2) {
      this.viewContactId = contact.id
    }
  }

  public onChannelInfo() {
    this.drawerChannelInfo = !this.drawerChannelInfo
  }

  private onMediaFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      if (!this.file.type.match('image.*')) {
        this.error = {
          message: 'You can only send images',
          timeout: 2000
        };
        return;
      }

      this.dialogConfirmActionTitle = 'Do you want send image ?';
      this.dialogConfirmActionMessage = '';
      this.onDialogConfirmCancel = () => {
        this.file = null
      }
      this.onDialogConfirmActionPositiveCallback = async () => {
        this.dialogConfirmAction = false;
        // Check if the user is signed-in
        if (this.t2bUser) {
          const screenWindow: any = window;
          const _URL = screenWindow.URL || screenWindow.webkitURL;
          const img = new Image();
          img.onload = async () => {            
            let isSmsSent = false;               
            
            // mms image in cloud
            if(this.isSMS) {
              this.loading = true;
              const downloadUrl = await getCustomImageUrl(this.channel?.id, this.file);
              if(downloadUrl){
                isSmsSent = await this.smsCall(true, downloadUrl);
              }              
            }

            // add image in db
            if(!this.isSMS || isSmsSent){
              await channelsStore.sendChannelImageMessage({
                src: img.src,
                file: this.file,
                width: img.width,
                height: img.height
              })
            }
            
            this.file = null
            this.loading = false;
          };
          img.onerror = () => {
            this.showIssue(`not a valid file: ${this.file.type}`);
          };
          img.src = _URL.createObjectURL(this.file);
        }
      }
      this.confirm = 'Yes, send'
      this.dialogConfirmAction = true;
      this.loading = false;
    }
  }

  private onMediaAttachmentSelected(event) {

    let files = event.target.files
    if(files.length == 1){
      let file = event.target.files[0];
      if (file.type.match('image/*')) {
        this.onMediaFileSelected(event)
        return
      }
    }

    this._files = event.target.files
    const screenWindow: any = window;
    const _URL = screenWindow.URL || screenWindow.webkitURL;

    this.onDialogConfirmActionPositiveCallback = async (files) => {
      this.dialogConfirmAction = false;
      if (this.t2bUser) {
        try{
          if(!this.isSMS){
            for (const file of files) {
              if (file.type.match('image/*')) {
                const img = new Image();
                img.src = _URL.createObjectURL(file);
                img.onload = async () => {
                  try {
                    await channelsStore.sendChannelImageMessage({
                        src: img.src,
                        file: file,
                        width: img.width,
                        height: img.height
                      });
                    
                  } catch (error) {
                    console.error(
                        'There was an error uploading a file to Cloud Storage:',
                        error
                    );
                  }
                };
                img.onerror = () => {
                  this.showIssue(`not a valid file: ${this.file.type}`);
                };
              }else{
                await channelsStore.sendChannelAttachmentMessage({
                  src: _URL.createObjectURL(file),
                  file: file
                });
              }
            }
          }else{
            
          }
        } catch (error) {
            console.error(
                'There was an error uploading a file to Cloud Storage:',
                error
            );
        }
      }
    }

    this.onDialogConfirmCancel = () => {
        this._files = null
    }

    for (const file of this._files) {
      console.log(`${file.name}: ${file.size} bytes`);
      
    }

    this.dialogConfirmActionTitle = this._files.length + ' files';

    this.confirm = 'Send'
    this.dialogConfirmAction = true ;

  }

  private closeLocation() {
    this.showMap = false
    this.location = null
    this.resetSelectedLocation()
  }

  private messageTapped_TypeImage(message: any, forward: boolean) {
    this.imageTypeMessage = message;
    this.imageTypeMessageForward = forward
    this.showImage = true;
  }

  private closeImageDialog() {
    this.showImage = false
    this.imageTypeMessage = null;
  }

  private showLocation(geopoint: any) {
    this.location = {lat: geopoint.latitude, lng: geopoint.longitude}
    this.showMap = true
  }

  private async sendLocation() {
    if (!!this.selectedLocation) {
      await channelsStore.sendChannelLocationMessage()
      this.closeLocation();
    }
  }

  private async sendMessage(event) {
    const message = this.channelMessageText.trim()
    let isSmsSent = true;

    if ((event.key === 'Enter' && event.shiftKey) ||
        (event.key === 'Enter' && event.ctrlKey)) {
      return;
    }
    if (message === '') {
      return
    }
    if (this.messageToEdit !== null) {
      await channelsStore.editChannelTextMessage({id: this.messageToEdit.id, text: message})
      this.channelMessageText = ''
      this.messageToEdit = null;
      return;
    }
    
    // send sms
    if(this.isSMS){
      isSmsSent = await this.smsCall(false, '');
    }

    // send text
    if(!this.isSMS || isSmsSent){
      await channelsStore.sendChannelTextMessage({text: message, isSMS: this.isSMS})
    }

    this.channelMessageText = ''    
  }

  private async smsCall(isMMS?: boolean, imageUrl?: string){    
   
    try{
        if(this.isSMS){

          this.loading = true;
          let phone_userId = this.phoneNumbers.join(',');

          // send sms          
          let smsResponse = await this.sendSMS({              
              from: this.smsSenderId,             
              text: this.channelMessageText,
              isFirstSMS: this.isFirstSMS,
              isChannel: true,
              channelId: this.channel?.id,
              channelName: this.channel?.name, 
              to_toId: phone_userId,
              isMMS: isMMS,
              imageUrl: imageUrl
          });

          // response
          this.loading = false;
          if(smsResponse && smsResponse.errorCode === '0'){
            if(!isMMS){
              this.showInfo(constants.SMS_SUCCESS);
            }
            else{
              this.showInfo(constants.MMS_SUCCESS);
            }
            this.loading = false;
            return true
          }
          else{            
            let errorMsg:string = !isMMS ? constants.SMS_FAILED : constants.MMS_FAILED;
            if(smsResponse?.errorText){              
              errorMsg = smsResponse?.errorText;
            }
            
            //error alert
            console.error(errorMsg);
            this.showIssue(errorMsg);
          }

        }
      } catch (e) {
        this.showIssue(constants.SMS_PHONE_INVALID);
        console.error("channel: Error in smsCall");
        console.error(e);
    }  

    this.loading = false;
    return false
  }

  private async onChangeChatType(isLoad: boolean = false){ 

    // validate 
    if(isLoad){
      this.isSMS = false
    } 
    else {      
      await this.getPhoneNumbers();

      if(this.smsSenderId === ''){  
        console.error(constants.SMS_SENDER_ID_INVALID);       
        this.showIssue(constants.SMS_SENDER_ID_INVALID);
        this.isSMS = false;      
      }
      else if(!this.phoneNumbers || this.phoneNumbers.length === 0){  
        console.error(constants.SMS_PHONE_UNKNOWN);       
        this.showIssue(constants.SMS_PHONE_UNKNOWN);
        this.isSMS = false;      
      }
    }

    // toggle
    const el = document.querySelector('#chatType');
    if(el){
      el.classList.remove('chat-type-app');
      el.classList.remove('chat-type-sms');

      if(this.isSMS)
        el.classList.add('chat-type-sms');
      else
        el.classList.add('chat-type-app');
    }
  }

  private downloadImage(message) {
    const { name } = message.image
    const storageUri = `/businesses/${this.t2bUser.business?.id}/channels/${this.channelId}/channelMessages/${message.id}/${name}`
    downloadFile({name, storageUri})
  }

  private clearSelectedMessage() {
    this.messageToEdit = null
  }

  async onOptionItemClicked(action, messageView) {
    const message = messageView.data;
    switch (action.type) {
      case ACTION_MESSAGE_DOWNLOAD: {
        this.downloadImage(message);
        break;
      }
      case ACTION_MESSAGE_EDIT: {
        this.channelMessageText = message.text;
        this.messageToEdit = message;
        break;
      }
      case ACTION_MESSAGE_COPY: {
        try {
          await copyTextToClipboard(message.text);
          this.showInfo('Message has been copied');
        } catch (err) {
          this.showIssue('Failed to copy message');
        }
        break;
      }
      case ACTION_MESSAGE_MORE: {
        this.moreOption = true;
        break;
      }
      case ACTION_MESSAGE_DELETE: {
        messageView.selected = true
        this.showDeleteMessagesDialog = true
        break;
      }
      default:
    }
  }

  private onDeleteMessages() {
    this.showDeleteMessagesDialog = true
  }

  private async deleteSelectedMessages(_: boolean) {
    await channelsStore.deleteMessages(this.selectedMessages?.map((message) => message.data.id))
    this.onCancelSelection()
  }

  private onCancelSelection() {
    this.moreOption = false
    this.selectedMessages.forEach((message) => message.selected = false)
  }

  private onChooseImage() {
    const fileField: HTMLInputElement = this.$refs.chooseImageForChannel as HTMLInputElement
    fileField.value = ''
    fileField.click()
  }

  private onAttachFile() {
    if (this.isSMS){
      this.onChooseImage()
      return
    }
    const fileField: HTMLInputElement = this.$refs.chooseAttachmentForChat as HTMLInputElement
    fileField.value = ''
    fileField.click()
  }

  @Watch('messagesLoaded')
  onMessageLoadedEvent(value, oldValue) {
    if (!value) {
      return;
    }
    const callback = (context) => {
      return function() {
        const messagesLayout: any = context.$refs.messagesLayout;
        if (messagesLayout) {
          messagesLayout.scrollTop = messagesLayout.scrollHeight;
          messagesLayout.style.visibility = 'visible';
          messagesLayout.style.scrollBehavior = 'smooth';
        }
        channelsStore.setMessagesLoaded(false);
      }
    };
    setTimeout(callback(this), 250);
  }

  private async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.init(to.params.channelId)
    });
  }

  private async beforeRouteUpdate(to, from, next) {
    this.onChangeChatType(true)
    this.viewContactId = null
    this.unsubscribeAll()
    await this.init(to.params.channelId)
    next();
  }

  private async beforeRouteLeave(to, from, next) {
    this.viewContactId = null
    this.unsubscribeAll()
    next()
  }

  mounted(){
    this.chatTypeVisible = true;
  }
}
