





































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '@/components/messages/BaseMessage.vue';
import {messageType} from '@/domain/model/types';
import DialogDetailSchedule from "@/components/chats/DialogDetailSchedule.vue";

@Component({
  name: 'request-appointment',
  components: {BaseMessage, DialogDetailSchedule}
})
export default class RequestAppointment extends Vue {
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop({default: true}) withMenu!: boolean;

  centered: boolean = true;

  get messageTypes() {
    return messageType;
  }

  get customer() {
    return this.message.data.recipient.name;
  }

  get sender() {
    return this.message.data.sender.name;
  }

  get appointId() {
    return this.message.data.appointId!;
  }

  get own() {
    return this.userId === this.message.data.sender.uid;
  }

  onDetail() {
    if (this.message.data.sender.uid === this.userId) {
      this.$router.push({
        name: 'appointments',
        params: {appointId: this.appointId},
        //query: {subtype: 'personal'}
      });
    } else {
      this.$emit('showDetailSchedule', this.appointId);
    }
  }
}
